body {
  padding-top: 65px;
  margin-top: 90px;
  margin: 0;
  font-family: "Times New Roman", serif, "Times New Roman", Times, serif,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100vh;
  height: 100%;
  background-color: #e0e0e0;
}

.red {
  color: red;
}
.blue {
  color: blue;
}
.black {
  color: black;
}
.yellow {
  color: yellow;
}
.cyan {
  color: cyan;
}
.green {
  color: green;
}
.greenyellow {
  color: greenyellow;
}
.magenta {
  color: magenta;
}
.purple {
  color: purple;
}
.skyblue {
  color: skyblue;
}
.white {
  color: white;
}
.whitesmoke {
  color: whitesmoke;
}
.blueviolet {
  color: blueviolet;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.signinBlock {
  margin-right: 20px;
  position: fixed;
  right: 0px;
  display: flex;
}

@media screen and (max-width: 576px) {
  .signinBlock {
    margin-right: 20px;
    position: fixed;
    right: 0px;
    display: block;
  }
}

/* Set height of the grid so .sidenav can be 100% (adjust as needed) */
.row.content {
  height: auto;
  text-align: left;
  justify-content: center;
}

.listHeading {
  text-overflow: ellipsis;
  color: #ffffff;
  background-color: #134d40;
  border-radius: 5px;
  font-weight: 200;
  font-size: large;
  font-family: Georgia, "Times New Roman", Times, serif;
  padding: 5px;
  text-align: center;
}

/* Set gray background color and 100% height */
.leftsidenav {
  padding-top: 20px;
  background-color: #b2dfdb;
  height: auto;
  text-align: left;
  backface-visibility: hidden;
  color: #818181;
}

.rightsidenav {
  padding-top: 20px;
  background-color: #b2dfdb;
  height: auto;
  text-align: left;
  backface-visibility: hidden;
  color: #818181;
}

@media screen and (max-width: 576px) {
  .leftsidenav {
    padding-top: 20px;
    background-color: #b2dfdb;
    height: auto;
    text-align: left;
    backface-visibility: hidden;
    color: #818181;
    display: none;
  }

  .rightsidenav {
    padding-top: 20px;
    background-color: #b2dfdb;
    height: auto;
    text-align: left;
    backface-visibility: hidden;
    color: #818181;
  }
}

@media screen and (min-width: 576px) {
  .leftNavMobileView {
    display: none;
  }
}

.leftsidenav a {
  font-weight: 500;
  color: rgb(33, 11, 122);
}

.rightsidenav a {
  color: rgb(33, 11, 122);
}

.leftsidenav a:hover {
  background-color: white;
}

.rightsidenav a:hover {
  background-color: white;
}

/* On small screens, set height to 'auto' for sidenav and grid */
@media screen and (max-width: 767px) {
  .sidenav {
    height: auto;
    padding: 15px;
    background-color: #4545674e;
  }
  .row.content {
    height: auto;
  }
}

.centre {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.contentBar {
  background-color: #dde3ead2;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
  height: auto;
}

@media screen and (max-width: 576px) {
  .contentBar {
    background-color: #dde3ead2;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
    height: auto;
    margin-top: 20px;
  }
}

.footer {
  position: relative;
  text-align: center;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
}

.home {
  height: 100%;
  background-color: rgb(244, 241, 236);
}

.dashboard {
  background-color: rgb(244, 241, 236);
}

.dashboardHome {
  min-height: 100%;
  position: auto;
}

.middleBar {
  height: auto;
  min-height: 100%;
  text-align: center;
}

.cardBox {
  margin: 8px 4px 20px 20px;
}

.item {
  width: auto;
  min-height: 13rem;
  margin-left: 0px;
  margin-right: 0px;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 576px) {
  .item {
    width: auto;
    min-height: 13rem;
    align-items: center;
    justify-content: centre;
    margin-left: 0px;
  }
}

.itemlink {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.itemtitle {
  background-color: #4a67454e;
  font-family: "Times New Roman", Georgia, Times, serif;
  font-weight: 200;
}

.tableTitle {
  background-color: #4545674e;
  font-family: Georgia, "Times New Roman", Times, serif;
  border-radius: 5px;
  color: white;
  background-color: #093917;
}

.tableBox {
  width: auto;
  overflow: wrap;
  word-wrap: break-word;
}

.dashboardHeading {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: medium;
  font-weight: bold;
  background-color: #f1f1f1;
}

.dashboardSubHeading {
  font-family: "Times New Roman", serif;
  font-size: large;
  font-weight: 500;
  background-color: #053628d4;
  color: #e0e0e0;
  text-align: start;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 5px;
  padding: 5px;
}

.SubHeading {
  font-family: "Times New Roman", serif;
  font-size: large;
  font-weight: 500;
  background-color: #053628d4;
  color: #e0e0e0;
  text-align: centre;
  border-radius: 8px;
}

@media screen and (max-width: 576px) {
  .SubHeading {
    font-family: "Times New Roman", serif;
    font-size: large;
    font-weight: 500;
    background-color: #053628d4;
    color: #e0e0e0;
    text-align: centre;
    border-radius: 8px;
    margin-top: 20px;
  }
}

.table th {
  font-family: "Times New Roman", Times, serif, Candara;
  font-weight: 500;
  font-size: large;
  background-color: #a8c7c0;
  border-radius: 10px;
}

.table tbody tr td a {
  font-family: "Times New Roman", Times, serif, Candara;
  color: #093917;
  word-wrap:break-word;
}

.table tbody tr td {
  table-layout: fixed;
  word-wrap:break-word;
}
.nav-item a:hover {
  background-color: #818181;
}

.navbar-brand a:hover {
  background-color: #818181;
}

.alert {
  font-family: "Times New Roman", serif;
  height: 35px;
  color: #021e03;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 10px;
  margin-right: 0px;
  margin-left: 0px;
  padding-top: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media screen and (max-width: 576px) {
  .alert {
    font-family: "Times New Roman", serif;
    color: #021e03;
    width: 100%;
    margin-top: 0px;
    padding-top: 5px;
    margin-right: 0px;
    margin-left: 0px;
    align-items: center;
    justify-content: center;
    height: 55px;
  }
}

.label {
  color: white;
  padding: 8px;
  font-weight: 200;
}

.other {
  background-color: #e7e7e7;
  color: black;
}

.pagination {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 0px;
  color: rgb(134, 79, 79);

}

.searchBar {
  color: #f1f1f1;
  display: flex;
  text-align: center;
  justify-content: center;
  margin-left: 4px;
}

.searchBarForm {
  display: flex;
  width: 50%;
}

@media screen and (max-width: 576px) {
  .searchBar {
    color: #f1f1f1;
    display: flex;
    text-align: center;
    justify-content: center;
    height: 25px;
    margin-bottom: 20px;
  }

  .searchBarForm {
    display: flex;
    width: 100%;
    position: fixed;
    z-index: 100;
    height: 55px;
  }

  .searchBarFormLink {
    display: none;
  }
}

.middleDashBoard {
  overflow-y: auto;
  height: auto;
}

.dashBoardBox {
  border: 1px solid #a3c9c1;
  border-radius: 10px;
  margin-top: 2px;
  margin-bottom: 10px;
}

@media screen and (max-width: 576px) {
  .dashBoardBox {
    display: block;
    border: 1px solid #a3c9c1;
    border-radius: 10px;
    margin-top: 2px;
    margin-bottom: 2px;
    justify-content: center;
    margin-bottom: 10px;
  }
}

.fileUploadForm {
  display: flex;
  font-family: "Times New Roman", Times, serif;
  padding-top: 7px;
  background-color: #f1f1f1;
  height: 100%;
  text-align: left;
  text-align: center;
  justify-content: center;
}

@media screen and (max-width: 576px) {
  .fileUploadForm {
    display: block;
    font-family: "Times New Roman", Times, serif;
    padding-top: 7px;
    background-color: #f1f1f1;
    height: 100%;
    text-align: left;
    text-align: center;
    justify-content: center;
    margin-top: 10px;
  }
}

.aboutText {
  background-color: #dde3ead2;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  color: #011309;
  text-decoration: none;
  font-size: 16px;
}

.loginPage {
  background-color: #dde3ead2;
  border-radius: 8px;
  margin-bottom: 16px;
  width: 25%;
  text-align: center;
  justify-content: center;
}

@media screen and (max-width: 576px) {
  .loginPage {
    background-color: #dde3ead2;
    border-radius: 8px;
    margin-bottom: 16px;
    width: 80%;
    text-align: center;
    justify-content: center;
  }

  .positionMiddle {
    margin-top: 110px;
  }
}

@media screen and (max-width: 576px) {
  .contactForm {
    margin-top: 45px;
  }
}

.dashBoardLogin {
  margin-top: 60px;
}

@media screen and (max-width: 576px) {
  .dashBoardLogin {
    margin-top: 0px;
  }
}

.text-start:hover {
  background-color: #f2f9f9e2;
  text-decoration: underline;
}

.alignBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.salaryTable {
  border-collapse: separate;
  border-spacing:0 0px;
}

.salaryTable td {
  padding: 6px;
}