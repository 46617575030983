a {
    text-decoration: none;
    /* display: inline-block; */
  }
  
  a:hover {
    background-color: #ddd;
    color: black;
  }
  
  .previous {
    background-color: #04AA6D;
    color: black;
    margin: 3px;
    padding: 8px 16px;
  }
  
  .next {
    background-color: #04AA6D;
    color: white;
    margin: 3px;
    padding: 8px 16px;

  }

  .pagination {
    justify-content: center;
  }
  